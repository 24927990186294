body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h2 {
  color: #222222;
  text-align: center;
}

a:hover {
  text-decoration: none !important;
  transition: 0.5s;
}

.footer a:hover {
  color: #ffffff;
  opacity: 1;
  text-decoration: none !important;
}

input {
  padding-left: 10px !important;
}

.menu_nav {
  background: transparent;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 90px;
  align-items: center;
  border-bottom: 1px solid #666666;
  box-shadow: none;
}

.logo_img {
  width: 170px;
  max-width: 100%;
}

#logo_text {
  text-align: right;
}

#logo_text h3 {
  font-size: 18px;
  margin-top: 0 !important;
  color: #81755c;
  font-style: italic;
  line-height: 1.5;
  font-weight: 600;
}

.nav-wrap {
  margin: 10px 45px;
}

.header_menu_ul {
  margin: 0 auto;
}

.header_menu_ul li {
  padding: 0 30px;
  font-size: 14px;
  border-bottom: none;
}

.to_select_parent,
.from_select_parent {
  padding-bottom: 20px !important;
}

#aduls,
#children {
  background: #ffffff !important;
  color: #222222 !important;
  font-size: 18px;
  border: 1px solid #8F8F8F;
  border-radius: 0 !important;
  width: 100%;
  height: 47px;
}

.header_menu_ul li a {
  color: #222222;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  font-family: Domine;
}
ul.header_menu_ul li.active a {
 font-weight: 600;
}

.header_light .header_menu_ul li a {
  color: #a7a7a7;
}
.header_light .header_menu_ul li a.btn {
  color: #a7a7a7;
  border:2px solid #a7a7a7 !important;
  line-height: 1.5rem !important;
}

.header_light .menu a:hover {
  color: #f5f5f5 !important;
}

#nav-mobile .header_menu_ul li a {
  color: #060606;
  margin: 0;
}
.header_dark .header_menu_ul li a {
  color: #222222;
}

.make_underline {
  border-bottom: 3px solid #81755C !important;
  border-radius: 2px;
}

nav ul a:hover {
  background-color: initial !important;
}

.section_1 .col-sm-12 center p {
  font-size: 50px;
  font-weight: 100;
  color: #81755C;
}

.section_1 .col-sm-6 p {
  text-align: left;
  color: #81755C;
  font-size: 30px;
}

p.terms_p {
  font-size: 14px;
  color: #2A1E1E;
  text-align: justify !important;
}

.section_2 {
  border-radius: 10px;
  background: #E6E1E1;
  padding-top: 20px;
}

.weight_100 {
  font-weight: 100;
  font-size: 27px !important;
}

.book_transfer {
  margin: 50px 0 !important;
}

.title {
  width: 100%;
}

.section_5 {
  border-top: 1px solid #81755c;
}

/* .card {
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  max-height: 170px;
  background: transparent !important;
  box-shadow: none !important;
  cursor: pointer;
} */

.card .card-action {
  border-top: none !important;
}

.card_service {
  width: 50% !important;
  display: inline-block !important;
}

.card .card-content p {
  font-weight: 100;
  font-size: 16px;
  color: #81755c !important;
}

.home_welcome {
  height: auto !important;
  padding: 0 20px 50px;
}

.col-sm-3.cars_home {
  max-width: 16% !important;
}

.card_service {
  transition: .5s;
}

.fleet_cars>.row {
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home-car {
  position: relative;
  width: 100%;
  object-fit: cover;
  max-height: 600px;
}

#right_window {
  position: absolute;
  bottom: 50px;
  right: 30px;
  z-index: 5;
  background-color: #2A1E1E;
  border-radius: 10px;
  text-align: center;
  font-family: 'Roboto', 'sans-serif';
  line-height: 15px;
  width: 45%;
  padding: 15px;
}

#right_window table {
  font-size: 14px;
}

.content_1_row,
.content_2_row {
  margin: 0 !important;
}

.book_trans {
  margin: 50px auto 30px;
}

.slider-control-centerright button,
.slider-control-centerleft button {
  border: 1px solid #222 !important;
  border-radius: 0 !important;
  background: rgb(255 255 255 / 50%) !important;
}
.slider-control-centerright button:focus,
.slider-control-centerleft button:focus {
  border: 1px solid #222 !important;
}

.slider-control-centerright button i,
.slider-control-centerleft button i {
  color: #222;
  font-size: 4rem;
}

.slider-control-centerright button {
  border-radius: 10px 0 0 10px;
}

.slider-control-centerleft button {
  border-radius: 0 10px 10px 0;
}

button:focus,
.btn:focus {
  outline: none;
  box-shadow: none !important;
  border: 0 !important;
}

.def_images {
  max-height: 700px !important;
  margin-bottom: 30px;
}

.flex-input {
  display: flex;
}

.flex-input .Select {
  border: 1px solid #8F8F8F !important;
  padding: 0 0 0 5px !important;
}

.flex-input .material-icons {
  line-height: 50px;
  float: right;
}

.row.people_header_title {
  padding: 0 0 20px;
  text-align: left;
}

.react-date-picker__inputGroup,
.react-time-picker__inputGroup {
  width: 190px;
}

.react-time-picker__inputGroup__hour,
.react-time-picker__inputGroup__minute {
  width: 50% !important;
}

.react-date-picker__wrapper,
.react-time-picker__wrapper {
  border: none !important;
}

.date_picker,
.time_picker {
  border-bottom: 1px solid #81755c;
}

input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
  width: 40px !important;
}

.alert-success {
  font-size: 20px !important;
  font-family: 'Poppins';
  color: #FFFFFF !important;
  background-color: #222222 !important;
  border-color: #222222 !important;
  margin: 3rem 0 0;
}

.info_window {
  color: #81755c;
}

.error {
  padding-bottom: 10px;
  font-style: italic;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 96% !important;
}

.dropdown-menu {
  width: 300px;
}

td.day {
  padding: 5px;
  border-radius: 0 !important;
}

td.active.day {
  background: #222222 !important;
}

.Hunter-time-picker .active .Hunter-hour-name {
  background-color: #222222 !important;
}

.Hunter-time-picker .Hunter-clean-btn {
  background-color: #222222 !important;
  border: 5px solid #222222;
}

.Hunter-time-picker .Hunter-minute.active {
  background-color: #222222 !important;
}

.Hunter-time-picker .Hunter-minute:hover,
.Hunter-time-picker .Hunter-hour-name:hover {
  color: #999 !important;
}

.alert.thank_you_booking.alert-success {
  margin: 0 auto;
  background: #ffffff !important;
  color: #222222 !important;
  font-size: 24px !important;
  border: none;
}

.header_menu_ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header_menu_ul .menu {
  display: inline-block;
  padding: 0;
}

.menu a {
  position: relative;
  display: block;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}

.menu a:hover {
  text-decoration: none;
  color: #878787 !important;
}

/* nav ul li.active a:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 15px;
  transform: translateX(-50%);
  content: url('/images/link_hover.svg');
  transition: 0.5s;
} */

#logo a:before {
  display: none;
}

nav ul li.active {
  background-color: unset !important;
}

.mobile_icon {
  display: none !important;
}

::selection {
  background-color: #222222;
  color: #fff;
}

#sidenav-overlay {
  display: none !important;
}

.book_vip_transfer.book_vip_transfer_top {
  width: 280px;
  margin: 20px auto;
}

.home-text {
  color: #81755C;
  text-align: left;
  font-weight: 100;
  font-size: 50px !important;
}

.title_h3 {
  font-weight: 100;
  font-size: 40px;
  color: #81755C;
}

#video {
  position: relative;
  padding-bottom: 57.25%;
  /* 16:9 */
  height: 0;
}

#video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hover_effect {
  width: 0;
  height: 100%;
  background: #81755C;
  position: absolute;
  left: 0;
  top: 0;
  transition: .5s;
  opacity: 0;
  z-index: -5;
}

.button_home {
  transition: .5s;
}

.button_home:hover .hover_effect {
  width: 100%;
  opacity: 1;
}

.button_home:hover,
.button_home:hover i {
  color: #1E1616 !important;
}

.covid-btn-block h5 {
  color: #81755C;
}

.covid-btn-block .col-sm-6 {
  border-top: 1px solid #81755C;
  padding: 10px 20px;
  text-align: left;
}

.covid_vip_transfer {
  width: 250px;
  border-radius: 10px;
  border: 1px solid #81755C;
  padding: 2px;
}

.button_covid {
  transition: .5s;
  padding: 5px 10px 10px 10px !important;
  height: 50px !important;
  width: 100%;
  color: #81755C !important;
  font-size: 20px;
}

.button_covid:hover .hover_effect {
  width: 100%;
  opacity: 1;
}

.button_covid:hover,
.button_covid:hover i {
  color: #1E1616 !important;
}

.covid_icon {
  margin-left: 10px;
}

.title h1 {
  font-weight: 100;
  color: #81755C;
  font-size: 40px !important;
}

.section_1 .col-sm-6 h2 {
  text-align: left;
}

.footer_h5 {
  font-size: 16px;
  margin: 5px 0 !important;
}

.covid-block ul {
  padding-bottom: 20px;
  color: #1E1616
}

.covid-block ul li {
  display: block;
  text-align: left;
  float: unset;
}

@media only screen and (min-width: 993px) {
  .container {
    width: calc(100% - 30px) !important;
  }
}

@media (max-width: 400px) {
  .dropdown-menu {
    width: 250px;
  }
}