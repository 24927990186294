ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
li {
    float: left;
}
li a {
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}
li a:hover {
    background-color: #111111;
    text-decoration: none;
}
.header_section {
    background: transparent;
    height: 60px;
}

@media only screen and (max-width: 992px) {
    .nav-wrap {
        display: none !important;
    }
}
.header_parent_div .menu_nav .nav-wrapper .s12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
nav .nav-wrapper {
    width: 100%;
}
ul.menu_nav {
    width: 100%;
}
ul.header_menu_ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}