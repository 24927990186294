@import url('https://fonts.googleapis.com/css2?family=Domine&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.video {
  pointer-events: none;
}

.slider-control-bottomcenter {
  display: none !important;
}

.header-img {
  background-image: url("/images/header.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter:brightness(0.1);
}
.loaded .header-img{
  animation: reveal 1s ease-out forwards;
}
  
@keyframes reveal{
  to{
    filter:brightness(1);
  }
}

.header-img.about_page{
  background-image: url("/images/about_header.jpg");
}
.header-img.fleet_page{
  background-image: url("/images/solution.jpg");
}
.header-img.contact_page{
  background-image: url("/images/contact_header.jpg");
}
/* 
.header-img .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #F7F7F7;
  opacity: 0.7;
} */

.header-img h1 {
  font-family: 'Domine';
  font-size: 40px !important;
  color: #a7a7a7;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
  transform: translateY(50px);
  opacity: 0;
}

.header-img p {
  transform: translateY(-15px);
  opacity: 0;
}
h1.section_title{
    transform: translateY(50px);
    opacity: 0;
}
.loaded .header-img p{
  animation: fadeIn 1s cubic-bezier(0, 0.21, 0.18, 1) 0.8s forwards;
}
.loaded .header-img h1, h1.section_title{
  animation: fadeIn 1s cubic-bezier(0, 0.21, 0.18, 1) 0.5s forwards;
}

@keyframes fadeIn {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.header-img p {
  font-family: 'Domine';
  font-size: 20px !important;
  color: #ffffff;
  z-index: 1;
}

.fleet_car_slider{
  background-color: #222222;
  min-height: 300px;
  position: relative;
  padding-top: 120px;
}

.btn.btn-style {
  font-family: 'Domine';
  background: transparent;
  box-shadow: none;
  padding: 3px 20px 32px;
  font-size: 20px;
  text-transform: none;
  border: 1px solid #a7a7a7;
  border-radius: 0 ;
  transition: 0.5s;
}

.btn-style-sm {
  max-width: 150px;
  min-width: 150px;
  width: 150px;
  font-size: 18px !important;
  padding: 20px !important;
}

.btn-style-outline {
  border-color:#222222 !important;
  border-radius: 0 !important;
  box-shadow: none;
  background: transparent;  
  color: #222222;
  font-family: 'Domine';
  text-transform: initial;
}

.btn-style:hover {
  color: #222222 !important;
  background: #fff !important;
    border: 1px solid #222222 !important;
}

.btn-style-outline:hover {
  color: #fff !important;
  background: #222222 !important;
}



.effecto{
    border-color: #222222 !important;
    border-radius: 0 !important;
    box-shadow: none;
    background: transparent;
    color: #222222;
    font-family: 'Domine';
    text-transform: initial;

    position: relative;
    transition-delay: 0.4s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.4s;
}

.effecto::before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.4s;
  background-color: #222222;
}
.effecto::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.4s;
  background-color: #222222;
  color: #ffffff;
  z-index: -10;
}
.effecto:hover{
  background-color: #222222;
  color: #ffffff !important;
  transition-delay: 0.4s;
}
.effecto:hover::before{
  width: 100%;
}
.effecto:hover::after{
  height: 100%;
  transition-delay: 0.4s;
}

.btn.fish{
  position: relative;
}
.btn.fish::before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #131212;
  z-index: -5;
  border: 4px solid rgb(255, 255, 255);
  opacity: 0;
  transform: scaleX(1) scaleY(1.1);
  animation: blink 5s infinite;
  animation-timing-function: cubic-bezier(0.07, 0.32, 0.02, 0.99);
  
}

.btn.fish.white_fish::before
{
  background-color: #ffffff;
  border: 4px solid #131212;
}

@keyframes blink {
  29%{ opacity: 0;}
  30%{
    opacity: 0.4;
    transform: scaleX(1) scaleY(1.1);
  }
  50%{
    opacity: 0;
    transform: scaleX(1.3) scaleY(2);
  }
 
}



.btn.btn-white{
  background-color: transparent;
  border: 1px solid #ffffff;
}

.btn.btn-white:focus,.btn.btn-white:hover{
  background-color: #222222;
  border: 1px solid #ffffff;
}

.btn:focus,
.btn-large:focus,
.btn-floating:focus{ background-color: #acacac;}

.covid_section {
  width: 700px;
  max-width: 100%;
  height: 30px;
  opacity: 0.3;
  border-top: 1px solid #262D35;
  margin-top: 40px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header_parent_div {
  border: none;
  position: absolute;
  z-index: 9;
  width: 100%;
  top: 0;
}

footer.page-footer {
  background: #222222 !important;
  padding: 30px 0 10px;
  font-family: Poppins;
}

.footer_h3 {
  font-family: Domine;
  font-size: 20px;
  line-height: 36px;
  color: #8F8F8F;
  padding-top: 15px;
  text-align: center;
  border-top: 1px solid #8f8f8f;
}

.footer-links {
  color: #FFFFFF;
  opacity: 0.5;
  display: block;
  text-align: left;
  font-family: Poppins;
  margin-top: 10px;
}

.footer-links.privacy {
  padding-left: 20px;
}

a.footer-logo {
  margin-bottom: 80px;
}
.footer_icon{
  width: 100px;
  height: 40px;
  margin:0 auto 5px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.alp_icon { background-image: url('/images/logo/alp.svg'); }
.alpdel_icon { background-image: url('/images/logo/alpdelivery.svg'); }
.alpexc_icon { background-image: url('/images/logo/alpexcursion.svg'); }
.discover_icon { background-image: url('/images/logo/discover.svg'); }
.eba_icon { background-image: url('/images/logo/eba.svg'); }
.swiss_icon { background-image: url('/images/logo/swiss.svg'); width: 150px;}
.trans_icon { background-image: url('/images/logo/trans.svg'); }


.container.footer-link {
  padding-top: 10px !important;
}

.partner-icons {
  display: flex;
}

.partner-icons a {
  margin:10px;
  width: calc(50% - 20px);
  opacity: 0.8;
}

.text-white-opacity {
  color: #FFFFFF;
  opacity: 0.8;
}

.color-opacity {
  color: #8F8F8F;
}

.section_1 .col-sm-4 {
  padding-top: 20px;
}

.section_2 .col-sm-12 h2 {
  color: #81755C;
}

.section_2 .col-sm-12 p {
  color: #2A1E1E;
}

.section_3 .col-sm-12 h2 {
  color: #81755C;
}

.section_4 {
  padding-left: 50px;
  padding-right: 50px;
}

.card_img_div {
  background: transparent !important;
}

.book_vip_transfer {
  width: 500px;
  border-radius: 10px;
  border: 1px solid #81755C;
  padding: 2px;
}


.btn.black_btn{
  background-color: #060606;
  color: #d9d9d9;
  border:1px solid transparent;
  border-radius: 3px;
  padding-left: 2rem;
  padding-right: 2rem;
  transition: .3s;
}
.btn.black_btn:hover, .btn.black_btn:focus{
  background-color: #ffffff;
  color: #060606;
  border:1px solid #060606 !important;
}

.form-sec-title {
  font-family: 'Domine';
}

.material_row {
  width: 100%;
  height: 100%;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}

.material_row .col-sm-12 center h3 {
  font-size: 32px;
  color: #81755C;
}

.content_1_row {
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.content_1_row .col-sm-12 center {
  font-size: 40px;
  color: #81755C;
  margin-bottom: 20px;
}

.content_1_row .col-sm-12 p {
  text-align: left;
  color: #2A1E1E;
}

.card .card-content {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 0 !important;
}

.content_2_row .col-sm-12 {
  text-align: left;
  color: #2A1E1E;
  padding-left: 50px;
  padding-right: 50px;
}

.contact_section_1 .col-sm-5 .validate+label {
  width: 0 !important;
}

.contact_section_1 {
  background: #E6E1E1;
  margin: 0 !important;
}

.contact_section_parent_div {
  padding: 0;
}

#map {
  width: 100%;
  height: 475px;
  background-color: transparent;
}

.col.s2.calendar_icon {
  padding: 0 !important;
  margin-left: -2px !important;
}

.footer-copyright {
  color: #8f8f8f !important;
}

.footer-copyright .span_color {
  padding:0 5px;
  color: #f3f3f3;
  opacity: 0.8;
}


.Select-control,
.Select-placeholder,
.Select-value-label,
.Select-input {
  height: 50px !important;
  line-height: 50px !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-radius: 0px !important;
}

.Select.is-searchable.is-focused:not(.is-open)>.Select-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: #d9d9d9 #ccc #b3b3b3;
  box-shadow: 0 0 1px -1px #cbcbcb;
  /*border-bottom: 2px solid black;*/
}

.Select-control {
  background: transparent !important;
  text-align: left;
}

.Select-value {
  width: calc(100% - 26px);
}

.form-horizontal input.form-control,
.form-horizontal .input-field input,
.form-horizontal textarea {
  border: 1px solid #444444 !important;
  padding-left: 10px !important;
}

.form-horizontal .input-field{
  padding: 0;
  margin-bottom: 2rem;
}

.form-horizontal .input-field input, .form-horizontal .input-field textarea {
  margin: 0 !important;
  width: fill-available;
  width: -webkit-fill-available;
}

.form-horizontal .input-field label {
  top: 5px;
  left: 15px;
}
.form-horizontal .active {
  color: #1E1616 !important;
  font-weight: 600;
}

.fill_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.car_info img{
  filter: brightness(0.2);
  padding-right: 12px;
  max-width: 35px;
  max-height: 35px;
}
/* 
.form-horizontal input:not(.browser-default):focus:not([readonly]) {
  color: #81755C !important;
  border-bottom: 1px solid #1E1616 !important;
  box-shadow: 0 1px 0 0 #1E1616 !important;
}

.material_row input:not(.browser-default):focus:not([readonly]) {
  color: #81755C !important;
  border-bottom: 1px solid #1E1616 !important;
  box-shadow: 0 1px 0 0 #1E1616 !important;
}

.form-horizontal textarea:not(.browser-default):focus:not([readonly]) {
  color: #81755C !important;
  border-bottom: 1px solid #1E1616 !important;
  box-shadow: 0 1px 0 0 #1E1616 !important;
} */

.Select-value span {
  color: #81755C !important;
}

.Select-menu-outer {
  overflow: auto !important;
}

.Select-menu-outer div {
  background: #F7F7F7 !important;
  text-align: left;
  color: #222222;
}

.Select-input {
  color: #222222 !important;
}

.Select-input>input {
  padding: 0 !important;
}

.Select-control>*:last-child {
  padding: 0 15px !important;
  border-left: 1px solid #8f8f8f;
}

.row .col.s6 {
  width: 100% !important;
}

.booking_section {
  min-height: 500px;
}

.from .row .col-sm-3 {
  color: #81755C;
  user-select: none;
}

.eff-1 {
  width: 140px;
  height: 50px;
  top: -2px;
  right: -140px;
  background: #222222;
  position: absolute;
  transition: all .5s ease;
  z-index: 1;
}

.book_vip_transfer:hover .eff-1 {
  right: 0 !important;
}

.book_vip_transfer:hover .button_home {
  color: #fff;
}

.datepicker-modal {
  max-height: 350px !important;
}

.timepicker-modal {
  max-height: 348px !important;
}

.datepicker {
  border-bottom: 1px solid transparent !important;
  color: #81755C;
}

.timepicker {
  border-bottom: 1px solid transparent !important;
  color: #81755C;
  box-shadow: 0 1px 0 0 transparent !important;
}

.datepicker-date-display span {
  color: #81755C;
}

.datepicker-date-display {
  background: #1E1616 !important;
}

.datepicker-table td.is-selected {
  background: #1E1616 !important;
}

.time .row .s2,
.s10 span {
  color: #81755C !important;
}

.time .row .s10 {
  color: #81755C !important;
}

.header_children {
  width: 100%;
  height: 30px;
  background: #50443E;
  text-align: center;
  color: white;
}

.header_adults {
  width: 100%;
  height: 30px;
  background: #50443E;
  text-align: center;
  color: white;
}

.count_peolple {
  margin-top: -20px;
}

.count_peolple td {
  padding: 0 !important;
}

.count_peolple td .btn-group .btn {
  text-align: center;
  border-radius: 0 !important;
  border: 1px solid !important;
}

.plus,
.minus {
  background: #626262 !important;
  color: #8F8F8F !important;
  font-size: 24px !important;
  padding: 0 !important;
  min-width: 40px;
  line-height: 31px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 47px !important;
}

.contact-img {
  height: 100%;
  object-fit: cover;
}

.people_header_title {
  background: transparent;
  color: #81755C;
  padding-left: 10px;
  padding-right: 10px;
  border: 0 !important;
}

.people_header_title .material-icons {
  color: #81755C;
}

.bord {
  border: 0 !important;
}

.contact_section_parent_div .fz25 {
  font-size: 25px;
}

.contact_section_parent_div center {
  color: #81755C;
  font-weight: 100;
}

.footer-link .footer-links:not(:last-child) {
  border-right: 1px solid;
  padding: 0 40px !important;
}

.section_services {
  background-color: #e9e9e9;
  background-image: url("/images/services/service_block.png");
  background-position: right top;
  background-repeat: no-repeat;
  background-size: min( 50vw, 550px);
  min-height: 500px;
  position: relative;
  padding: 10px 0 40px;
}

.services_page_top{
  margin-top: 120px;
  margin-bottom: 20px;
  background-image: url("/images/services/service_block.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size:auto min(50vw, 400px);
  min-height: 400px;
}
.services_page_top .section_title{
  padding-top: 22%;
}

.section_fleet {
  background: #F7F7F7;
  position: relative;
  padding-top: 20px;
  padding-bottom: 10px;
}

.service_items_mob {
  width: 100%;
}
.service-block {
  /* width: 50%;
  max-width: calc(50% - 5px);
  margin-right: 5px;
  padding: 5px; */
  display: flex;
  margin-top: 20px;
  transition: 0.5s;
}

.service-block .card-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 15px 10px;
  font-weight: normal;
  color: #222222;
  transition: 0.5s;
}

.service-block .card-content h3{
  font-family: 'Domine';
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.service-block .card-content p {
  font-family: 'Poppins';
  font-size: 16px;
  margin: 0;
}

.service-block .card-content img {
  width: 30px;
  margin-bottom: 10px;
  transition: .5s;
}

.service-block img.chevrons-right {
  width: 15px;
  margin: 0;
}
.service-block .card_more {
  border-bottom: #222222 1px solid;
  width: fit-content;
  margin-top: 8px;
}

.service-block:hover {
  background-color: #222222;
}

.service-block:hover .card-content {
  color: #ffffff;
}

.service-block:hover .card-content img {
  filter: brightness(7);
}

.service-block-mob{
  position: relative;
  margin: 25px auto;
  border-radius: 12px;
  overflow: hidden;
}
.service-block-mob .card-content h3{
  font-size: 24px;;
  margin: 55px auto 10px;
}
.service-block-mob .card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Domine';
  text-align: center;
  color:#ffffff;
  padding: 3px 10px;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, .6) 40%, rgba(0, 0, 0, .9) 100%);
}
.service-img-mob{
  height: calc(40vw + 80px);
  width: 100%;
  object-fit: cover;
}

img {
  max-width: 100%;
}

.small_title {
  font-family: Poppins;
  font-size: 16px;
  z-index: 1;
  position: relative;
  padding-top: 40px;
  margin-bottom: 0;
}

.section_title {
  position: relative;
  font-family: 'Domine';
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 3.5rem 0 1.5rem;
  z-index: 1;
}
.section_subtitle {
  font-family: 'Domine';
  font-size: 20px;
  font-weight: 600;
}

.card_title {
  font-family: 'Domine';
  font-size: 17px;
  font-weight: 600;
  color: #222222;
  margin: 0;
}
.card_type {
  font-family: 'Poppins';
  color: #ffffff;
  margin: 0;
  background-color: #575757;
  background: linear-gradient(44.01deg, rgba(102, 99, 100, 1) 0%, rgba(186, 188, 190, 1) 58%, rgba(141, 144, 146, 1) 100%);
  border-radius: 8px;
  padding: 2px 8px 0 8px;
  display: inline-block;
  font-weight: 400;
}

.ECO.card_type{
  position: relative;
  color: #3B8E48;
  color: #ffffff;
  padding-right: 28px;
  background: linear-gradient(58.56deg, rgba(75, 151, 90, 1) 0%, rgba(150, 184, 102, 1) 58%, rgba(75, 151, 90, 1) 99%);
}
.ECO.card_type::after{
  content: '';
  width: 14px;
  height: 15px;
  position: absolute;
  top: 5px;
  right: 10px;
  background-image: url('/images/cars/icons/ecoico.svg');
  background-repeat: no-repeat;
}

.EXCLUSIVE.card_type{
  /* color: #D1A750; */
  background: linear-gradient(44.01deg, rgba(207, 166, 79, 1) 0%, rgba(240, 209, 160, 1) 58%, rgba(207, 166, 79, 1) 99%);
}

.card_img {
  height: 130px;
  width: 100%;
  object-fit: contain;
}
 .cars_home{
  background-color: #ffffff;
  padding: 15px;
  border-radius: 9px;
  height: 100%;
 }
 .cars_home:hover {
   box-shadow: 0 3px 5px #00000022;
 }

.car_options {
  padding: 0 15px;
}
.car_option {
  color:#666666;
  padding: 15px 0 7px;
  white-space: nowrap;
}

.car_option span{
  padding: 0 13px 0 3px;
  display: inline-block;
  transform: translateY(2px);
  font-size: 16px;
}

.car_features{ 
  columns: 2; 
  column-fill: auto;
  height: 140px; 
}
.car_feature{ 
  white-space: nowrap; 
}

.car_feature_text{
  color:#666666;
  font-size: 14px;
  margin-left: 3px;
  white-space: nowrap;
}

.fleet_card img.seats{
  margin: 10px auto;
  display: block;height: 120px;
  width: 100%;
  object-fit: contain;
}
.fleet_card .book{
  margin-top: 10px;
  padding: 3px 5px;
  transform: translateY(2px);
  height: 27px;
  font-size: 12px;
  width: 100%;
}
.fleet_card .book:hover{
  background-color: #777777;
}

.bg_fluid {
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 48% auto;
  background-origin: content-box;
  min-height: 300px;
}

.section_about {
  background-image: url("/images/about-home.jpg");
  position: relative;
  padding-bottom: 70px;
}
.values_section{
  background-image: url("/images/values.png");
  background-position: right 150px;
  background-repeat: no-repeat;
  background-size: calc(20vw + 360px) auto;
}

.text {
  font-family: 'Poppins';
  font-weight: normal;
  font-size: 16px;
  color: #8F8F8F;
  margin: 0;
}

img.small_cars {
  width: 35px !important;
  margin: 0 auto;
}

.section_welcome {
  background: -webkit-radial-gradient(bottom, #222222, #060606);
  background: radial-gradient(ellipse at center bottom, #222222, #060606);
  padding:60px 0;
  color:#a7a7a7;
}
.section_welcome h2,.section_solution h2, .block-black h2{
  color:#a7a7a7;
}

.section_welcome .bg_fluid {
  background-image: url("/images/welcome_home.jpg");
  min-height: 400px;
}

.section_exclusive{
  background-color: #e9e9e9;
  background-image: url(/images/exclusive/exclusive-home.webp);
}

.section_solution{
  background: -webkit-radial-gradient(top, #444444, #060606);
  background: radial-gradient(ellipse at center top, #444444, #060606);
  background-image: url("/images/solution.jpg");
  background-size: min(1400px,160%) auto;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #000000;
  min-height: 490px;
  padding:60px 0 120px;
  color:#a7a7a7;
}

.my-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.price-block {
  position: relative;
  width: 100%;
  border: #707070;
}

.price-block .price-block-header {
  background: #FFFFFF;
  font-family: 'Poppins';
  font-size: 28px;
  color: #222222;
  padding: 15px 0;
  position: relative;
  border-bottom: 1px solid #8F8F8F;
}

.price-block-header:before {
  content: '';
  width: 40px;
  height: 40px;
  background: #fff;
  position: absolute;
  bottom: -20px;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg);
  z-index: 0;
  left: 50%;
  border-bottom: 1px solid #8F8F8F;
  border-right: 1px solid #8F8F8F;
}

.price-block-content p{
  line-height: 14px;
  margin-bottom: 0;
  margin-top: 15px;
}
.price-block-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 160px;
  font-family: 'Poppins';
  color: #333333;
}

.price-block:first-of-type .price-block-content {
  background: #F7F7F7;
}

.price-block:nth-of-type(2) .price-block-content {
  background: #F1F1F1;
}

.price-block:nth-of-type(3) .price-block-content {
  background: #FFFFFF;
}

.price-block:nth-of-type(4) .price-block-content {
  background: #F9F9F9;
}

.newsletter_inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.newsletter_inputs .input-field {
  max-width: 350px;
  margin-right: 20px;
  margin-top: 0;
}

.newsletter_inputs .input-field input {
  min-width: 250px;
}

.subscribe-text {
  font-family: 'Domine';
  font-size: 26px;
  text-transform: uppercase;
  color: #222222;
  margin-bottom: 0;
}

.validate {
  border-bottom: 1px solid #c64444 !important;
  margin: 0 !important;
}

.img-mob {
  display: none;
}

a.mobile_logo {
  display: none;
  z-index: 2;
}

.other-header {
  height: 300px;
  background: url('/images/other-bg.jpg');
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-title-block {
  position: relative;
}


.page-title-block h1 {
  font-family: 'Domine';
  font-weight: bold;
  font-size: 34px;
  text-align: center;
  text-transform: uppercase;
  color: #222222;
  position: relative;
  z-index: 2;
}
.page-title-block h5 {
  font-family: 'Domine';
  text-align: center;
  text-transform: uppercase;
  color: #222222;
  position: relative;
  z-index: 2;
}

.service-item {
  max-width: 300px;
  margin: 0 auto 20px;
}

.service-card {
  padding: 20px 0 50px !important;
  border: 1px solid #8F8F8F !important;
  min-height: 150px;
  justify-content: center;
  transition: 0.5s;
}

.service-card .card-action {
  padding: 0 10px !important;
  min-height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-card .card-action p {
  margin: 0;
  font-family: 'Domine';
  font-size: 16px;
  text-align: center;
  color: #222222;
}

.card_service-hover {
  display: none !important;
}

img.card_service {
  width: 40px !important;
  height: 25px !important;
  object-fit: contain;
  position: absolute !important;
  transform: translate(-50%, -50%) rotate(-45deg);
  top: 50% !important;
  left: 50% !important;
  transition: 0.5s;
}
img.card_service_mob {
  width: 40px !important;
  height: 32px !important;
  margin: auto;
  filter: brightness(0.8);
/*   position: absolute !important;
  transform: translate(-50%, -50%) rotate(-45deg);
  top: 50% !important;
  left: 50% !important;
  transition: 0.5s; */
}

.rotate-brd {
  background: #FFFFFF;
  border: 1px solid #8F8F8F;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  padding: 5px;
  display: flex;
  align-items: center;
  animation: opacity 0.5s;
}

.rotate-brd-static {
  transform: rotate(45deg);
}

.rotate-brd-hover {
  display: none;
  background: #222222;
  animation: opacity 0.5s, rotation 0.5s;
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotation {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.service-card .service-icons {
  position: relative;
}

.service-card .service-icons:after {
  content: '';
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #8F8F8F;
  z-index: -1;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.5s;
}

.service-card:hover,
.business_transfers .service-card,
.car_disposal_services .service-card,
.point-to-point_transfers .service-card,
.airport_transfer_services .service-card,
.city_tour_and_excursion .service-card,
.handicapped_services .service-card,
.tesla_model_x .service-card,
.tesla_model_s .service-card,
.mb_s_class .service-card,
.mb_e_class .service-card,
.mb_v_class .service-card,
.mb_v_exclusive .service-card,
.mb_minivan .service-card,
.mb_sprinter_cabrio .service-card,
.mb_gle_450_hybrid .service-card,
.mb_sprinter .service-card {
  border: 1px solid #222222 !important;
}

.service-card:hover .service-icons:after,
.business_transfers .service-icons:after,
.car_disposal_services .service-icons:after,
.point-to-point_transfers .service-icons:after,
.airport_transfer_services .service-icons:after,
.city_tour_and_excursion .service-icons:after,
.handicapped_services .service-icons:after,
.tesla_model_x .service-icons:after,
.tesla_model_s .service-icons:after,
.mb_s_class .service-icons:after,
.mb_e_class .service-icons:after,
.mb_v_class .service-icons:after,
.mb_v_exclusive .service-icons:after,
.mb_minivan .service-icons:after,
.mb_sprinter_cabrio .service-icons:after,
.mb_gle_450_hybrid .service-icons:after,
.mb_sprinter .service-icons:after {
  border-bottom: 1px solid #222222;
}

.service-card:hover .rotate-brd-static,
.business_transfers .rotate-brd-static,
.car_disposal_services .rotate-brd-static,
.point-to-point_transfers .rotate-brd-static,
.airport_transfer_services .rotate-brd-static,
.city_tour_and_excursion .rotate-brd-static,
.handicapped_services .rotate-brd-static,
.tesla_model_x .rotate-brd-static,
.tesla_model_s .rotate-brd-static,
.mb_s_class .rotate-brd-static,
.mb_e_class .rotate-brd-static,
.mb_v_class .rotate-brd-static,
.mb_v_exclusive .rotate-brd-static,
.mb_minivan .rotate-brd-static,
.mb_sprinter_cabrio .rotate-brd-static,
.mb_gle_450_hybrid .rotate-brd-static,
.mb_sprinter .rotate-brd-static {
  display: none !important;
}

.service-card:hover .rotate-brd-hover,
.business_transfers .rotate-brd-hover,
.car_disposal_services .rotate-brd-hover,
.point-to-point_transfers .rotate-brd-hover,
.airport_transfer_services .rotate-brd-hover,
.city_tour_and_excursion .rotate-brd-hover,
.handicapped_services .rotate-brd-hover,
.tesla_model_x .rotate-brd-hover,
.tesla_model_s .rotate-brd-hover,
.mb_s_class .rotate-brd-hover,
.mb_e_class .rotate-brd-hover,
.mb_v_class .rotate-brd-hover,
.mb_v_exclusive .rotate-brd-hover,
.mb_minivan .rotate-brd-hover,
.mb_sprinter_cabrio .rotate-brd-hover,
.mb_gle_450_hybrid .rotate-brd-hover,
.mb_sprinter .rotate-brd-hover {
  display: block !important;
}

.service-card:hover img.card_service,
.business_transfers img.card_service,
.car_disposal_services img.card_service,
.point-to-point_transfers img.card_service,
.airport_transfer_services img.card_service,
.city_tour_and_excursion img.card_service,
.handicapped_services img.card_service,
.tesla_model_x img.card_service,
.tesla_model_s img.card_service,
.mb_s_class img.card_service,
.mb_e_class img.card_service,
.mb_v_class img.card_service,
.mb_v_exclusive img.card_service,
.mb_minivan img.card_service,
.mb_sprinter_cabrio img.card_service,
.mb_gle_450_hybrid img.card_service,
.mb_sprinter img.card_service {
  transform: translate(-50%, -50%) rotate(0deg);
}


p.about_p {
  font-family: 'Poppins';
  color: #8F8F8F;
  text-align: left;
  font-size: 18px;
}

.col_2 {
  column-count: 2;
}

.img-shadow {
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.16))
}

.block-white {
  position: relative;
  padding-bottom: 30px;
}
.block-white div{
  z-index: 3;
}

.block-black {
  position: relative;
  background: #060606;
  color: #a7a7a7;
}

.z-index-3 {
  z-index: 3;
}

.space {
  height: 1px;
}

.car-items.service-item {
  width: 100%;
  margin: 0 5px 20px 5px;
}

.slider-frame ul.slider-list {
  height: 600px !important;
}

.slider-frame ul.slider-list img {
  object-fit: cover;
  height: 600px !important;
}

.input-field.col label:not(.label-icon).active {
  left: 12px;
  top: 8px;
  font-size: 14px;
}

.input-field input {
  padding: 0 !important;
  margin: 0 0 20px 0 !important;
}

.text-lightgrey {
  color: #8F8F8F;
}

.contact-information {
  line-height: 26px;
  text-align: left;
  padding: 20px 20px 0 0;
  border-top: 1px solid #707070;
  margin: 2rem 0;
  font-family: 'Poppins';
}
.contact-information a {
  color: #212529;
}

.contact-list {
  list-style: none;
}

.contact-list li {
  list-style-type: none;
  padding:  0 20px 0 0;
}

.error {
  padding-bottom: 0;
  font-style: normal;
  text-align: left;
}

.section.booking_section {
  box-shadow: 0 15px 30px rgb(0 0 0 / 16%);
  max-width: 1100px;
  margin: 30px auto 100px;
  padding: 0;
}

.home_p, .terms_p, .panel-body{
  font-size: 16px;
  font-family: 'Poppins';
}
.terms_p h5 {
  font-family: 'Domine';
  font-size: 24px;
  font-weight: 600;
  color: #222222;
  margin-top: 25px;
  margin-bottom: 10px;
}

.terms_p a,
.terms_p a:hover {
  color: #222222 !important;
}

nav .button-collapse {
  margin: 0 !important;
  float: right !important;
}

nav .button-collapse i {
  color: #999999 !important;
  font-size: 40px !important;
  margin-top: 6px !important;
}

.panel.panel-default {
  border-bottom: 1px solid #8F8F8F;
  padding: 20px 0;
  position: relative;
}

.panel-title a {
  font-family: 'Domine';
  font-size: 24px;
  color: #222222;
  width: calc(100% - 50px);
  display: block;
}

.panel-title a:hover {
  color: #222222 !important;
}



.panel.panel-default:after {
  content: '+';
  font-size: 22px;
  font-weight: bold;
  color: #222222;
  position: absolute;
  top: 16px;
  padding: 0 20px;
  right: 10px;
}

/* .panel.panel-default:before {
  background: #fff;
  content: '';
  width: 25px;
  height: 25px;
  position: absolute;
  top: 22px;
  border: 1px solid #222222;
  right: 54px;
  transform: rotate(45deg);
} */

.panel.panel-default.clicked:after {
  content: '-';
  right: 13px;
}

.covid-block p,
.covid-block ul li {
  font-family: 'Poppins';
  font-size: 18px;
  color: #8F8F8F;
  text-align: left;
}

.partner-icons a {
  opacity: 0.6;
  transition: 0.5s;
  transform-origin: 20% 50%;
}
.partner-icons a:hover {
  opacity: 1;
  transform: scale(1.1);
}





@media only screen and (max-width: 1200px) {
  .col-sm-7 {
    padding-left: 0 !important;
    margin-top: 20px
  }
  .flex-100 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .count_peolple {
    margin-top: 15px;
  }
  .block-white:after {
    width: 88px;
    height: 88px;
    bottom: -44px;
  }
  .contact-list {
    flex-direction: column;
    line-height: 30px;
  }
  .section_services {
    background-size: min( 50vw, 400px);
  }
}






@media only screen and (max-width: 992px) {
  .menu_nav {
    height: 70px;
  }
  #nav-mobile .header_menu_ul .menu:first-child {
    background-color: #FFFFFF;
  }
  #nav-mobile .header_menu_ul .menu:not(:first-child) {
    width: 100%;
    padding: 0;
    transition: .5s;
    border-bottom: 1px solid #222222;
    border-image: linear-gradient(to right, #ffffff 0%, #222222 50%, #ffffff 100%);
    border-image-slice: 3;
  }
  #nav-mobile .header_menu_ul .menu a {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }
  a.mobile_logo {
    display: block;
    position: absolute;
    width: 80px;
    top: 20px;
    float: left;
  }
  img.mobile_logo_img {
    max-width: 80px;
  }
  .side-nav {
    width: 250px !important;
  }
  .mobile_icon {
    display: block !important;
  }
  li#icon {
    padding: 10px 0;
  }
  #right_window {
    width: 50%;
  }
  .values_section {
    background-image: none;
  }
  .service-item {
    max-width: 100px;
  }

  .service-item-block {
    max-width: unset !important;
  }
  .section_about {
    background: none;
    padding: 20px 0;
  }

  .img-mob {
    display: block;
  }
  .img-mob img {
    max-width: 400px;
    width: 100%;
    object-fit: cover;
  }

  .footer_box {
    margin-bottom: 30px;
  }

  a.footer-logo {
    margin-bottom: 26px;
  }

  .partner-icons {
    flex-wrap: wrap;
  }
  ul.header_menu_ul {
    flex-direction: column;
  }
  #logo {
    display: none;
  }
  .menu a:before {
    display: none;
  }
  .contact-img {
    display: none;
  }

  .service-card {
    padding: 10px 0 2px !important;
    min-height: unset;
  }

  .price-block {
    max-width: 45%;
    margin : 5px;
  }
  .price-block-header:before {
    width: 17px;
    height: 17px;
    bottom: -9px;
  }
  .price-block-content{
    min-height: 54px;
    padding: 10px 3px 15px;
  }
  .price-block .price-block-header{
    padding: 5px 0;
    font-size: 22px;
  }

  .service-card:hover,
  .business_transfers .card,
  .car_disposal_services .card,
  .point-to-point_transfers .card,
  .airport_transfer_services .card,
  .city_tour_and_excursion .card,
  .handicapped_services .card,
  .tesla_model_x,
  .tesla_model_s,
  .mb_s_class,
  .mb_e_class,
  .mb_v_class,
  .mb_v_exclusive,
  .mb_minivan,
  .mb_sprinter_cabrio,
  .mb_gle_450_hybrid,
  .mb_sprinter {
    background: #dddddd !important;
  }
  .section_welcome .bg_fluid, .section_exclusive.bg_fluid {
    background-image: none;
  }
  .section_solution{
    background-size: 100%;
    background-position: center bottom;
    min-height: 400px;
    padding-top: 20px;
  }
  
}






@media only screen and (max-width: 768px) {
  .w-100-mob {
    width: 100% !important;
    padding: 0 !important;
  }
  .header-img {
    padding: 0 20px;
    min-height: 50vh;
  }
  .header-img h1 {
    font-size: 22px !important;
    padding-top: 1em;
  }
  .header-img p{
    font-size: 16px !important;
    margin: 0;
  }
  .slider-frame ul.slider-list,
  .slider-frame ul.slider-list img {
    height: 300px !important;
  }
  .header_section {
    padding-bottom: 75px !important;
  }
  .logo_img {
    top: 0;
    left: 10px;
    width: 130px;
  }
  #logo_text {
    width: 60%;
    position: absolute;
    right: 0;
    top: 10px;
  }
  #logo_text h3 {
    font-size: 15px;
    line-height: 1.5;
    margin: 0;
  }
  .slider-control-bottomcenter {
    display: none !important;
  }

  .slider-control-centerright button i,
  .slider-control-centerleft button i{
      font-size: 2rem;
    }
  .section_title{
    font-size: 20px;
    margin-bottom: 10px;
    padding-top: 1.2rem;
    margin-top: 0;
  }
  .small_title{padding-top: 20px;}
  .content_1_row,
  .content_2_row {
    padding: 0;
  }
  .content_2_row .col-sm-12 {
    padding: 0 15px;
  }
  .def_images {
    max-height: 200px !important;
  }
  .row.content_2_row img {
    object-fit: contain;
  }
  
  .auto_home {
    width: 100% !important;
    background-size: 200px;
    padding: 65px;
    background-position: center;
  }
  #right_window {
    bottom: 0;
    right: 0;
    width: 100%;
    top: auto;
  }
  .newsletter_inputs .input-field {
    width: 100% !important;
    padding: 0 !important;
    margin-right: 0;
  }
  .fromto_icon {
    width: 10% !important;
    margin-left: 10px;
  }
  #subscribe {
    margin-bottom: 10px;
  }

  .footer_icon {
    width: 90px;
  }

  .footer-links {
    width: 50%;
    padding: 0 15px;
  }

  .book_vip_transfer {
    width: 280px;
  }
  .home_welcome .col-sm-12 h2 {
    font-size: 30px;
  }
  .Hunter-time-picker {
    width: 280px;
    left: 50px !important;
  }
  .Hunter-time-picker:before,
  .Hunter-time-picker:after {
    display: none;
  }
  .Hunter-time-picker .Hunter-minute-wrap {
    width: 140px;
  }
  .footer-link {
    padding-top: 15px !important;
  }
  .footer-link .footer-links:not(:last-child) {
    border-right: none;
    margin: 0;
  }
  p.home-text {
    font-size: 35px !important;
    text-align: center !important;
  }
  .book_vip_transfer_top {
    margin-top: 30px !important;
    margin-bottom: 50px !important;
  }
  .weight_100 {
    font-weight: 500;
    font-size: 14px !important;
    text-align: center !important;
    line-height: 1.5;
  }
  .video {
    pointer-events: all;
  }
  #video {
    padding-bottom: 60%;
  }
  .glass-block {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    padding-top: 30px;
  }
  .my-100 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .section.booking_section .row .col.s6 {
    min-width: 100%;
  }
  .section_welcome{
    padding-bottom: 40px;
  }

  .block-white:after {
    width: 55px;
    height: 55px;
    bottom: -28px;
  }
  .block-black{
    padding-top: 60px;
  }

  .services_page_top {
    margin-top: 80px;
    margin-bottom: 20px;
    min-height: max(47vw, 220px);
    background-size: contain;
  }

  p.car_info {
    margin-top: 15px;
    column-count: 2;
    line-height: 12px;
  }


  
}


@media only screen and (max-width: 576px) {
  .service-block {
    max-width: 100%;
    margin-right: 0;
  }
  .service-item {
    max-width: 70px;
  }
  .service-item-block {
    max-width: unset !important;
  }
  .car-items.service-item{
    margin: 0 0 15px;
  }
  .service-card {
    padding: 10px 0 2px !important;
    min-height: unset;
  }
  .service-card .card-action{
    min-height: 60px;
    padding: 0 3px !important;
  }
  .service-card .card-action p {
    font-family: 'Poppins';
    font-size: 13px;
  }
  .service-item {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  img.card_service_mob {
    width: 27px !important;
    height: 30px !important;
  }

  .service-block-mob {
    border-radius:0;
  }

  .price-block {
    max-width: 90%;
  }
}

.material_row i {
  color: #81755C;
}

.material_row .input-field .prefix.active {
  color: #81755C;
}
/* 
.material_row input:not(.browser-default):focus:not([readonly]) {
  color: #81755C !important;
  border-bottom: 1px solid #b6b6c1 !important;
  box-shadow: 0 1px 0 0 #b6b6c1 !important;
} */

.material_row .active {
  color: #b6b6c1 !important;
}

.slider-list {
  height: 700px !important;
}

.row.content_2_row img {
  width: 100%;
  max-height: 700px;
  object-fit: cover;
}

.span_count_people {
  margin-left: 30px;
}

#Hunter_time_picker ul {
  overflow: visible !important;
}